/*global YMK, site*/
(function ($, Drupal) {
  Drupal.behaviors.vtoLooksLandingPageComponentV1 = {
    attach: function (context) {
      var vtoAPIKey = Drupal.settings.vto.api_key;
      var vtoAPIUrl = Drupal.settings.vto.api_url;
      var vtoEvents = [
        'cameraClosed',
        'cameraFailed',
        'cameraOpened',
        'compare',
        'compareDisabled',
        'chosenModel',
        'chooseModelEntered',
        'closed',
        'engineClosed',
        'liveCameraEntered',
        'loaded',
        'loading',
        'kissDetected',
        'modelLoaded',
        'opened',
        'photoLoaded',
        'photoSaved',
        'reset',
        'uploadPhotoEntered',
        'videoLoaded',
        'shadeFinderStarted',
        'shadeFinderComplete',
        'shadeFinderCancelled',
        'zoom'
      ];
      var $window = $(window);
      var isMobile =
        $('body').hasClass('device-mobile') || (Modernizr.touch && $(window).width() < 1024);

      var gnavHeight = $('.js-site-header').height();
      var shopLookHeight = 140;
      var looksIframeMaxWidth = 900;
      var looksIframeMaxHeight = 1080;
      var heightToLoad = $window.height() - gnavHeight;
      var $looksPagination = $('.js-looks-destination-grid-pagination', context);
      var $looksIframeContainer = $('.js-product-vto-container', context);
      var isLooksVtoLoaded = false;
      var vtoLanguage = Drupal.settings.vto.language;
      var vtoLanguageCaFr = Drupal.settings.vto_language_fr;
      var isCaFr = document.location.hostname.indexOf('fr.maccosmetics.ca') !== -1;
      var vtoParams;
      var vtoOverlay;
      var iframeWidth;
      var iframeHeight;

      function calculateIframeWidthHeight() {
        vtoOverlay = isLooksVtoLoaded ? 0 : 10;
        iframeWidth = isMobile ? $window.width() : $window.width() / 2 + vtoOverlay;
        iframeHeight = isMobile ? $window.height() - shopLookHeight : heightToLoad;
        vtoParams.width = iframeWidth < looksIframeMaxWidth ? iframeWidth : looksIframeMaxWidth;
        vtoParams.height =
          iframeHeight < looksIframeMaxHeight ? iframeHeight : looksIframeMaxHeight;
        // height can't be bigger then the width.
        if (!isMobile) {
          vtoParams.height = vtoParams.height > vtoParams.width ? vtoParams.width : vtoParams.height;
        }
      }

      function initYouCam(skipEventListener) {
        vtoParams = {
          autoOpen: true,
          showCloseBtnOnLauncher: true
        };

        calculateIframeWidthHeight();

        if (isCaFr && vtoLanguageCaFr) {
          vtoParams.language = vtoLanguageCaFr;
        } else if (vtoLanguage) {
          vtoParams.language = vtoLanguage;
        }

        isLooksVtoLoaded = true;
        window.YMK.init(vtoParams);
        if (skipEventListener) {
          return false;
        }
        vtoEvents.forEach(function (youcamEvent) {
          window.YMK.addEventListener(youcamEvent, function (progress) {
            var looksGuid = $('.js-looks-destination-overlay-v1').data('looksGuid');
            var $shopLooks = $('.js-looks-destination-look-cta');
            var $ymkModuleContent = $('#YMK-module');
            var shopLooksButton = $window.height() - shopLookHeight;
            if (isMobile) {
              $looksPagination.addClass('looks-destination__grid-carousel__pagination-mobile');
              $looksIframeContainer.addClass('scroll-visible');
            }
            if (youcamEvent === 'closed') {
              if (isMobile) {
                $shopLooks.addClass('hidden');
              }
              $ymkModuleContent.addClass('hidden');
              $('body').removeClass('vto-overlay-launched');
              window.YMK.closeEngine();
            } else if (youcamEvent === 'engineClosed') {
              if (isMobile) {
                $shopLooks.addClass('hidden');
              }
            } else if (youcamEvent === 'loading') {
              if (isMobile) {
                $ymkModuleContent.after($shopLooks);
                $shopLooks.removeClass('hidden');
                $shopLooks.css('top', shopLooksButton + 'px');
              }
            } else if (youcamEvent === 'loaded') {
              YMK.applyMakeupByLook(looksGuid);
            }

            // Analytics tagging for YouCam events - Starts
            if (site && site.track) {
              var eventName;
              if (youcamEvent === 'loading') {
                if (progress === 10) {
                  eventName = 'loading-start';
                } else if (progress === 100) {
                  eventName = 'loading-complete';
                }
              } else {
                eventName = youcamEvent;
              }
              if (eventName && eventName !== '') {
                site.track.evtLink({
                  event_name: 'vto',
                  event_category: 'youcam',
                  event_action: eventName,
                  event_label: looksGuid
                });
              }
            }
            // Analytics tagging for YouCam events - Ends
          });
        });
      }

      $(document).on('openVtoModule', function (e) {
        e.preventDefault();
        initYouCam(true);
      });

      if (vtoAPIUrl !== null && vtoAPIKey !== null) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = vtoAPIUrl + vtoAPIKey;
        var scriptElement = document.getElementsByTagName('script')[0];
        scriptElement.parentNode.insertBefore(script, scriptElement);
      }

      window.ymkAsyncInit = function () {
        initYouCam();
      };
    }
  };
})(jQuery, Drupal);
